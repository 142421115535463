<template>
  <div class="screen-1" style="height: 150px;margin-bottom: 100px;width:400px;">
    <div style="width: 100%;text-align: center">
      <img src="/img/logo/logo.png" width="380px"><br>
    </div>

    <div class="email" style="margin-top: -10px">
      <div class="sec-2">
        <label for="email" style="font-size: 11pt;">Enter Your Access Code: </label>
        <input focus id="accessCodeInput" maxlength="6" style="letter-spacing: 12px !important; padding-left: 8px;width: 120px;display: inline-block;margin-left: 10px; border-radius: 4px; border: 1px solid #c0c0c0" type="password" name="auth_code" placeholder="" v-model="auth_code" autofocus/>
      </div>
    </div>
    <!--<button class="login" @click="authenticate" style="border-radius: 8px;background-color: #404040;">Login</button>-->
    <!--<div class="footer"><span>Sign up</span><span>Forgot Password?</span></div>-->
  </div>
</template>

<script>
import iziToast from 'izitoast'
export default {
  data() {
    return {
      halfOpacity: false,
      identity: null,
      passPhrase: null
    }
  },
  mounted() {
    let i = document.getElementById('accessCodeInput')
    let ctx = this
    i.addEventListener("keyup", function(event) {
      if (event.key == 'Enter') {
        event.preventDefault();
        ctx.authenticate()
      }
    });
  },
  methods: {
    authenticate() {
      $store.dispatch('App-User-api/login', {
        data: {auth_code: this.auth_code},
        method: 'authenticateByAuthCode'
      }).then((r) => {
        this.halfOpacity = true
        iziToast.success({
          title: 'Authenticated',
          message: 'Your dashboard is ready',
          position: 'topRight',
          close: false,
          timeout: 800,
          animateInside: true,
          onClosed: () => {}
        })
        setTimeout( () => {
          localStorage.removeItem('redirected_to_login')
          window.location = $router.resolve({name: 'portal-cpanel'}).resolved.fullPath;
          //$router.push({name: 'portal-cpanel-dashboard'})
        }, 900)
      }).catch( (e) => {
        iziToast.warning({
          title: 'Caution',
          message: 'You have to check your credentials',
          position: 'topRight',
          timeout: 1500,
          animateInside: true,
          close: false,
        });
      })
    }
  }
}
</script>
<style scoped>

</style>
